<template>
  <section class="gray pt-4">
    <div class="container-fluid" v-if="!is_loading">
      <div class="row">
        <div class="col-md-12">
          <a
            class="btn btn-danger float-end btn-sm py-2 me-2"
            href="#"
            @click.prevent="showAlert"
            >Delete</a
          >
          <router-link
            class="btn btn-warning float-end btn-sm py-2 me-2"
            :to="{ name: 'my-listings.edit' }"
            @click.prevent=""
            >Edit</router-link
          >
          <a
            class="btn btn-theme float-end btn-sm py-2"
            :href="
              $router.resolve({ name: 'page', params: { slug: property.slug } })
                .href
            "
            target="_blank"
            >View Post</a
          >
          <a
            href="#"
            class="btn btn-warning float-end btn-sm py-2 me-2"
            @click.prevent="show_unpublish = true"
            v-if="property.status == 'active'"
            >Unpublish</a
          >
          <a
            href="#"
            class="btn btn-success float-end btn-sm py-2 me-2"
            @click.prevent="toggleStatus"
            v-if="property.status == 'unpublished'"
            >Publish</a
          >
          <router-link
            class="btn btn-success float-end btn-sm py-2 me-2"
            :to="{ name: 'payment', params: { id: this.account.account }}"
            v-if="property.pending_payment"
            >Pay Now</router-link
          >
          <router-link
            class="btn btn-info btn-sm py-2 float-end me-2"
            href="#"
            v-if="can_promote"
            :to="{ name: 'promote', params: { id: property.uniqid }}"
            >Promote Property</router-link
          >
          <h2><small>#{{ property.uniqid }} - </small>{{ property.name }}</h2>
          <h5>
            <span
              :class="`btn btn-sm btn-${
                property.status == 'pending'
                  ? 'warning'
                  : property.status == 'active'
                  ? 'success'
                  : 'danger'
              } me-5`"
              >status: {{ property.status }}</span
            >
            <span class="me-5"
              ><i class="lni lni-money me-2"></i><small>KES</small>
              {{ property.amount }}</span
            >
            <span class="me-5"
              ><i class="lni lni-map-marker me-2"></i
              >{{ property.region }}</span
            >
            <span class="me-5"
              ><i class="lni lni-hourglass me-2"></i>{{ property.date }}</span
            >
          </h5>
        </div>
        <div class="col-md-12 mt-3">
          <div class="row">
            <div class="col-md-3">
              <div class="card">
                <div class="card-body">
                  <h5>Views</h5>
                  <h2>{{ property.views }}</h2>
                  <span>.</span>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card" role="button" @click.prevent="getStats('phone_number')">
                <div class="card-body">
                  <h5>Phone Number Requests</h5>
                  <h2>{{ property.phone_number_hits }}</h2>
                  <span class="text-muted text-tiny">View Request Details</span>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card" role="button" @click.prevent="getStats('whatsapp')">
                <div class="card-body">
                  <h5>Whatsapp Requests</h5>
                  <h2>{{ property.whatsapp_hits }}</h2>
                  <span class="text-muted text-tiny">View Request Details</span>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card" role="button" @click.prevent="getStats('email')">
                <div class="card-body">
                  <h5>Email Requests</h5>
                  <h2>{{ property.email_hits }}</h2>
                  <span class="text-muted text-tiny">View Request Details</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9 mt-4">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">
                Property Views
                <small class="text-muted" style="font-size: 16px"
                  >Last 30 Days</small
                >
              </h3>
              <chart :data="property.graph_data" type="line" />
            </div>
          </div>
        </div>
        <div
          class="col-md-3 mt-4"
          v-if="property.status == 'active' || property.status == 'pending'"
        >
          <div class="card">
            <div class="card-body text-center" v-if="property.featured">
              <img
                src="/assets/img/verified.png"
                alt="verified"
                height="100px"
              />
              <h4 class="mt-3">This Post Is Promoted</h4>
              <h5 class="text-muted">for</h5>
              <h2>{{ property.promoted_days }}</h2>
              <h5 class="text-muted">more days</h5>
            </div>
            <div class="card-body text-center" v-if="!property.featured">
              <h4>Promote this post</h4>
              <p class="my-3">
                Click the button to promote this post and get more views!
              </p>
              <p>
                <router-link
                  class="btn btn-success"
                  :to="{ name: 'promote', params: { id: property.uniqid }}"
                  v-if="!property.pending_payment"
                  >Promote Property</router-link
                >
                <router-link
                  class="btn btn-success"
                  :to="{ name: 'payment', params: { id: this.account.account }}"
                  v-if="property.pending_payment"
                  >Pay Now</router-link
                >
              </p>
              <p v-if="property.pending_payment">
                <a href="#" @click.prevent="cancelPromotion" class="btn btn-warning"
                  >Cancel Promotion</a
                >
              </p>
            </div>
          </div>
        </div>

        <modal :show="show_unpublish" @close="show_unpublish = false">
          <template slot="header">Unpublish this post.</template>
          <h5>Select a reason below.</h5>
          <hr />
          <form @submit.prevent="toggleStatus">
            <div class="promotion-group mb-1">
              <input
                type="radio"
                name="promote"
                required
                id="post-sold"
                value="sold"
                v-model="unpublish.reason"
              />
              <label for="post-sold">
                <h5 class="mb-0">Sold/Rented</h5>
                <p class="mb-0">This post got the desired result.</p>
              </label>
            </div>
            <div class="promotion-group mb-1">
              <input
                type="radio"
                name="promote"
                required
                id="post-unnecessary"
                value="unnecessary"
                v-model="unpublish.reason"
              />
              <label for="post-unnecessary">
                <h5 class="mb-0">Unnecessary</h5>
                <p class="mb-0">This post is not necessary.</p>
              </label>
            </div>
            <div class="promotion-group mb-1">
              <input
                type="radio"
                name="promote"
                required
                id="post-other"
                value="other"
                v-model="unpublish.reason"
              />
              <label for="post-other">
                <h5 class="mb-0">Other</h5>
                <p class="mb-0">Specify a reason.</p>
              </label>
            </div>
            <div class="form-group" v-if="unpublish.reason == 'other'">
              <textarea
                rows="2"
                placeholder="Reson for unpublishing"
                v-model="unpublish.text"
                class="form-control"
              ></textarea>
            </div>
            <div class="form-group mt-3">
              <a
                href="#"
                class="btn btn-secondary"
                @click.prevent="show_unpublish = false"
                >Cancel</a
              >
              <button class="btn btn-primary float-end">Submit</button>
            </div>
          </form>
        </modal>
      </div>
    </div>
    <modal :show="show_stats_modal" @close="show_stats_modal = false">
      <template slot="header">
        Requested {{ stats_data.title }} 
        <download-csv
          :json="stats_data.data"
          v-if="stats_data_loaded"
        />
      </template>
      <table class="table table-striped" v-if="stats_data_loaded">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>{{ stats_data.title }}</th>
            <th>URL</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="stats_data.data.length == 0">
            <td colspan="3">There is no data to display</td>
          </tr>
          <tr v-for="(user, i) in stats_data.data" :key="`stat-${i}`">
            <td>{{ i+1 }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user[stats_data_type] }}</td>
            <td>{{ user.url }}</td>
          </tr>
        </tbody>
      </table>
      <loading v-if="! stats_data_loaded" />
    </modal>
    <loading v-if="is_loading" />
  </section>
</template>

<script>
import Chart from "@/components/Chart";

export default {
  components: {
    Chart,
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      property: {},
      pricing: {},
      is_loading: true,
      define_promote: false,
      sending_data: false,
      account: {},
      show_unpublish: false,
      unpublish: {},
      show_stats_modal: false,
      stats_data: { data: [] },
      stats_data_loaded: false,
      stats_data_type: ''
    };
  },

  methods: {
    showAlert() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to undo this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletePost();
        }
      });
    },

    cancelPromotion() {
      this.axios
        .put(`/api/v1/my-listings/${this.property.id}/cancel-promote`)
        .then(() => {
          this.fetch()
        })
    },

    promote(pricing) {
      this.sending_data = true;

      let data = {
        pricing_id: pricing.id,
      };

      this.axios
        .put(`/api/v1/my-listings/${this.property.id}/promote`, data)
        .then((response) => {
          this.account = response.data.account;
          this.sending_data = false;
          this.define_promote = false;
          this.$router.push({
            name: "payment",
            params: { id: this.property.uniqid },
          });
        });
    },

    deletePost() {
      this.is_loading = true;
      this.axios
        .delete(`/api/v1/my-listings/${this.$route.params.slug}`)
        .then(() => {
          this.is_loading = false;
          this.$router.push({ name: "my-listings.index" });
        });
    },

    toggleStatus() {
      let data = {
        action: "status",
        unpublish: this.unpublish,
      };

      this.axios
        .put(`/api/v1/my-listings/${this.$route.params.slug}`, data)
        .then(() => {
          this.show_unpublish = false;
          this.fetch();
        });
    },

    fetch() {
      this.is_loading = true;

      this.axios
        .get(`/api/v1/my-listings/${this.$route.params.slug}`)
        .then((response) => {
          this.property = response.data.property;
          this.pricing = response.data.pricing;
          this.account = response.data.account;
          this.is_loading = false;
        });
    },

    getStats(type) {
      this.show_stats_modal = true
      this.stats_data_loaded = false,
      this.stats_data_type = type

      this.$axios.get(`/api/v1/my-listings/${this.property.id}/stats?type=${type}`).then(response => {
        this.stats_data = response.data.stats_data
        this.stats_data_loaded = true
      })
    }
  },

  computed: {
    can_promote() {
      return (
        !this.property.featured &&
        !this.property.pending_payment &&
        (this.property.status == "pending" || this.property.status == "active")
      );
    },
  },
};
</script>
